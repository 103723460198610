import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { NavigationCard, BaseCard } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { RSSIcon, MapPinIcon, InterrailIcon } from '@entur/icons';
import { cards } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<NavigationCard></NavigationCard>'} __scope={{
      props,
      DefaultLayout,
      NavigationCard,
      BaseCard,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      RSSIcon,
      MapPinIcon,
      InterrailIcon,
      cards,
      DefaultLayout,
      _frontmatter
    }} props={cards} style={{
      padding: '1rem'
    }} mdxType="Playground">
  <NavigationCard mdxType="NavigationCard"></NavigationCard>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "navigationcard"
    }}>{`NavigationCard`}</h3>
    <ImportStatement imports="NavigationCard" mdxType="ImportStatement" />
    <Props of={NavigationCard} mdxType="Props" />
    <p><inlineCode parentName="p">{`NavigationCard`}</inlineCode>{` kan brukes gjerne på landingssider for å gi oversiktlige linker til forskjellige undersider. `}<inlineCode parentName="p">{`NavigationCard`}</inlineCode>{` skal ha en beskrivende tekst under tittelen, og gjerne et beskrivende ikon.
Bruk helst eksempelet over, og ha 1.5rem/24px mellom hvert NavigationCard.`}</p>
    <p><inlineCode parentName="p">{`NavigationCard`}</inlineCode>{` med `}<inlineCode parentName="p">{`compact`}</inlineCode>{`-propen kan brukes på samme måte, men heller om man vil bruke mindre plass, eller ikke har en beskrivende tekst til tittelen.
Ettersom denne ikke har forflyttning ved hover, så kan du bruke lavere verdier for spacing mellom boksene.`}</p>
    <h3 {...{
      "id": "basecard"
    }}>{`BaseCard`}</h3>
    <ImportStatement imports="BaseCard" mdxType="ImportStatement" />
    <Props of={BaseCard} mdxType="Props" />
    <p><inlineCode parentName="p">{`BaseCard`}</inlineCode>{` er komponenten som `}<inlineCode parentName="p">{`NavigationCard`}</inlineCode>{` bygger seg oppå, og kan også brukes direkte, om man skulle ha behov stylingen som `}<inlineCode parentName="p">{`BaseCard`}</inlineCode>{` er "kjent" for.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Cards er flater som viser innhold og handlinger om et emne som man ønsker å fremheve på en side.
Card gir et kort sammendrag av innholdet, og lenker videre til en ny side med ytterligere detaljer.
Den skal være enkel å skanne etter relevant informasjon. Card kan for eksempel brukes som navigasjon
til et underliggende nivå, der man gjør et uttrekk av innholdet fra menyen som presenteres på en landingsside.`}</p>
    <p>{`NavigationCard skal ha en beskrivende tekst under tittelen. Teksten bør være kort og konsis, på max 3 linjer.
Ikon i et card er valgfritt, men bruker du ikoner, bør de være selvforklarende.
NavigationCard med compact-propen kan brukes på samme måte, men heller om man vil bruke mindre plass eller ikke har en beskrivende tekst til tittelen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      